import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const Form = (props) => {

  const formRef = useRef(null);
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const mobilePattern = /^[0-9]{10}$/;

  const handleSubmit = (e) => {
    e.preventDefault();
    const userEmail = formRef.current.user_email.value;
    const userMobile = formRef.current.user_mobile.value;
    if (!emailPattern.test(userEmail)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Email',
        text: 'Please enter a valid email address.',
      });
      return; 
    }
    if (!mobilePattern.test(userMobile)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Mobile Number',
        text: 'Please enter a valid 10-digit mobile number.',
      });
      return;
    }
    emailjs.sendForm('service_56xelui', 'template_38c1qv8', formRef.current, 'cpTPA4zZLmKmwE9nt')
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Your query was sent successfully. Thank you for contacting us!',
          showConfirmButton: true,
        });
        formRef.current.reset();
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.text
        });
      });
  };

  return (
    <>
      <div
        className="col-12 col-lg-6 pt-4 p-lg-4"
        style={{ border: "3px solid #6610f2" }}
      >
        <div className="contact-box">
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="user_name"
                    placeholder="Name"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="user_email"
                    placeholder="Email"
                   
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="user_mobile"
                    maxLength="10"
                    placeholder="Mobile Number"
                    />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    placeholder="Message"
                  />
                </div>
              </div>
              <div className="col-12 form-style">
                <button
                  type="submit"
                  className="btn btn-bordered active btn-block mt-3"
                >
                  <span className="text-white pr-3">
                    SUBMIT
                  </span>
                </button>
              </div>
            </div>
          </form>
          <p className="form-message" />
        </div>
      </div>
    </>
  );
};

export default Form;
