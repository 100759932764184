import React, { useState } from "react";
import App from "../../App.css";

const Banner1 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [splashShowData, setSplashShowData] = useState(true);
  const [loginShowData, setLoginShowData] = useState(false);
  const [fpwordShowData, setFpwordShowData] = useState(false);

  const splashToggle = () => {
    setSplashShowData(!splashShowData);
    setLoginShowData(false);
    setFpwordShowData(false);
  };

  const loginToggle = () => {
    setLoginShowData(!loginShowData);
    setSplashShowData(false);
    setFpwordShowData(false);
  };

  const fpwordToggle = () => {
    setFpwordShowData(!fpwordShowData);
    setSplashShowData(false);
    setLoginShowData(false);
  };

  const styles = {
    splashPopup: {
      backgroundColor: splashShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    loginPopup: {
      backgroundColor: loginShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    fpwordPopup: {
      backgroundColor: fpwordShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s "
    }),
  };

  return (
    <>
      {/* <section className="bg-grey">
        <div className="container flex-column d-flex justify-content-center align-items-center pt-5">
          <h2>CUSTOMER MODULE</h2>
          <div className="container  align-items-center justify-content-center d-flex mt-5">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/customImg-1.png"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-2.png"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-3.png"
                    alt="image"
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-12">
                <div
                  className="m-2 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`splashPopup ${splashShowData ? "active" : ""}`}
                    onClick={splashToggle}
                    style={styles.splashPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#318CE7",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {" "}
                      {splashShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>Splash Screen</span>
                  </div>
                  {splashShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: "black" }}>
                        The ﬁrst screen of the application will be the splash
                        screen. It will be visible for 3-4 seconds. Splash
                        screens typically serve to enhance the look and feel of
                        an application or website, hence they are often visually
                        appealing. They may also have animations, graphics, and
                        sound.
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className="m-2 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`loginPopup ${loginShowData ? "active" : ""}`}
                    onClick={loginToggle}
                    style={styles.loginPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#318CE7",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {loginShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>Login Screen</span>
                  </div>
                  {loginShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: "black" }}>
                        Then click on Login button.The user’s entered credential
                        – Email address/Username, Pass-word is valid and correct
                        or not. User will not get access to the re-stricted page
                        until he/she entered a valid email and password. If the
                        customer forgot their password, in that case, the
                        customer can reset their current password & set a new
                        password.
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className="m-2 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`fpwordPopup ${fpwordShowData ? "active" : ""}`}
                    onClick={fpwordToggle}
                    style={styles.fpwordPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#318CE7",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {fpwordShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>
                      Forgot Password Screen
                    </span>
                  </div>
                  {fpwordShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: "black" }}>
                        In the forgot password screen, if the customer forgot
                        his password so in that case he can reset the current
                        password & set a new one. He only needs to enter his
                        registered email address & will receive OTP. After
                        entering the received OTP, the user can reset his
                        current password & confirm a new password.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ***** Changed one *****  */}

      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <h4 style={{ textAlign: "center" }}>CUSTOMER MODULE</h4>
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0 ">
              <div className="col-lg-6 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/customImg-1.png"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-2.png"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-3.png"
                    alt="image"
                  />
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 px-lg-5 px-sm-0"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`splashPopup ${
                        splashShowData ? "active" : ""
                      }`}
                      onClick={splashToggle}
                      style={styles.splashPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#318CE7",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {splashShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Splash Screen
                      </span>
                    </div>
                    <div
                      style={styles.content(splashShowData)}
                      className="mt-2 mb-2"
                    >
                      <p
                        style={{
                          color: "black",
                          fontWeight: "450",
                          fontSize: "1.2rem",
                        }}
                      >
                        The ﬁrst screen of the application will be the splash
                        screen. It will be visible for 3-4 seconds. Splash
                        screens typically serve to enhance the look and feel of
                        an application or website, hence they are often visually
                        appealing. They may also have animations, graphics, and
                        sound.
                      </p>
                    </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`loginPopup ${loginShowData ? "active" : ""}`}
                      onClick={loginToggle}
                      style={styles.loginPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#318CE7",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {loginShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Login Screen
                      </span>
                    </div>
                    <div
                      style={styles.content(loginShowData)}
                      className="mt-2 mb-2"
                    >
                      <p
                        style={{
                          color: "black",
                          fontWeight: "450",
                          fontSize: "1.2rem",
                        }}
                      >
                        Then click on Login button.The user’s entered credential
                        – Email address/Username, Pass-word is valid and correct
                        or not. User will not get access to the re-stricted page
                        until he/she entered a valid email and password. If the
                        customer forgot their password, in that case, the
                        customer can reset their current password & set a new
                        password.
                      </p>
                    </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`fpwordPopup ${
                        fpwordShowData ? "active" : ""
                      }`}
                      onClick={fpwordToggle}
                      style={styles.fpwordPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#318CE7",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {fpwordShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Forgot Password Screen
                      </span>
                    </div>
                    <div
                      style={styles.content(fpwordShowData)}
                      className="mt-2 mb-2"
                    >
                      <p
                        style={{
                          color: "black",
                          fontWeight: "450",
                          fontSize: "1.2rem",
                        }}
                      >
                        In the forgot password screen, if the customer forgot
                        his password so in that case he can reset the current
                        password & set a new one. He only needs to enter his
                        registered email address & will receive OTP. After
                        entering the received OTP, the user can reset his
                        current password & confirm a new password.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner1;
