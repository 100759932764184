import React from "react";
import jsonData from "../../data/db.json"; // import your JSON data

const Single = ({ pageName }) => {
  const {
    "question-answers": questionAnswers,
    paragraph1,
    paragraph2,
  } = jsonData[pageName];

  return (
    <article className="single-blog-details">
      <div className="blog-content digimax-blog p-3">
        <div className="blog-details">
          <p className="d-none d-sm-block mt-4">{paragraph1}</p>
          <p className="d-none d-sm-block mt-4">{paragraph2}</p>
        </div>
      </div>
      {questionAnswers &&
        questionAnswers.map((qa, index) => (
          <div key={index} className="blog-content digimax-blog p-3">
            <div className="blog-details">
              <h5
                className="blog-title py-3"
                style={{ color: "rgb(53, 178, 254)" }}
              >
                {qa.question}
              </h5>
              <p>{qa.answer}</p>
              {/* {qa.img && <img className="mt-3" src={qa.img} alt="image" />} */}

              {/* ***** changed one ***** */}

              {qa.img && (
                <img
                  className="mt-3"
                  src={qa.img}
                  alt="img"
                  style={{ height: "55vh", objectFit: "cover", width: "100vw" }}
                />
              )}
            </div>
          </div>
        ))}
    </article>
  );
};

export default Single;
