import React from "react";

const Banner2 = (props) => {
  const { textColor } = props;
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center bg-grey">
        <div className="mt-3 text-center">
          <h2
            className={`${textColor ? textColor : "text-white"}`}
            style={{ fontWeight: 800 }}
          >
            Trusted & supported by
          </h2>
        </div>
        <div className="d-flex flex-wrap justify-content-center mb-5">
          <div
            className="card support-box-style m-3"
            style={{ maxWidth: "11rem", borderRadius: "8%" }}
          >
            <div className="card-body d-flex justify-content-center align-items-center p-1 shadow bg-body rounded">
              <a
                href="https://www.google.com/search?q=aaban+solutions&sca_esv=80879160ea65325f&sca_upv=1&sxsrf=ADLYWIKkl0MxZCb5hCsnSEVOQA3cK3i0YQ%3A1716367566827&ei=zrBNZqeAMr6Svr0PwMumkA0&gs_ssp=eJzj4tVP1zc0TDa1KDRLKjIyYLRSNaiwsDBISzFMskwxNzBPMzE2tjKoMDK1MEpKTLGwTLM0ME40MfbiT0xMSsxTKM7PKS3JzM8rBgCvdBSd&oq=aabansolutions&gs_lp=Egxnd3Mtd2l6LXNlcnAiDmFhYmFuc29sdXRpb25zKgIIADIQEC4YgAQYsAMYxwEYDRivATIJEAAYsAMYDRgeMg4QABiABBiwAxiGAxiKBTIOEAAYgAQYsAMYhgMYigUyCxAAGIAEGLADGKIEMgsQABiABBiwAxiiBEjwEVAAWABwAXgAkAEAmAEAoAEAqgEAuAEByAEAmAIBoAIGmAMAiAYBkAYGkgcBMaAHAA&sclient=gws-wiz-serp#ip=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/Google-review.jpg" alt="Google Review" />
              </a>
            </div>
          </div>
          <div
            className="card support-box-style m-3"
            style={{ maxWidth: "11rem", borderRadius: "8%" }}
          >
            <div className="card-body d-flex justify-content-center align-items-center p-1 shadow bg-body rounded">
              <img src="/img/hospot.jpg" alt="Hubspot" />
            </div>
          </div>
          <div
            className="card support-box-style m-3"
            style={{ maxWidth: "11rem", borderRadius: "8%" }}
          >
            <div className="card-body d-flex justify-content-center align-items-center p-1 shadow bg-body rounded">
              <img src="/img/bbb-logo.png" alt="bbb logo" />
            </div>
          </div>

          <div
            className="card support-box-style m-3"
            style={{ maxWidth: "11rem", borderRadius: "8%" }}
          >
            <div className="card-body d-flex justify-content-center align-items-center p-1 shadow bg-body rounded">
              <a
                href="https://clutch.co/profile/aaban-solutions#highlights"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/cluch.jpg" alt="Clutch" />
              </a>
            </div>
          </div>

          <div
            className="card support-box-style m-3"
            style={{ maxWidth: "11rem", borderRadius: "8%" }}
          >
            <div className="card-body d-flex justify-content-center align-items-center p-1 shadow bg-body rounded">
              <a
                href="https://www.sitejabber.com/users/aabans"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/img/sitejabber3.png"
                  alt="sitejabber"
                  style={{ borderRadius: "5%" }}
                />
              </a>
            </div>
          </div>
          <div
            className="card support-box-style m-3"
            style={{ maxWidth: "11rem", borderRadius: "8%" }}
          >
            <div className="card-body d-flex justify-content-center align-items-center p-1 shadow bg-body rounded">
              <a
                href="https://trustpilot.com/review/aabansolutions.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/Trustpilot.jpg" alt="Trustpilot" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner2;
