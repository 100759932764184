import React from "react";
import { Helmet } from "react-helmet";
import HeaderOne from "../components/Header/HeaderOne";
import Scrollup from "../components/Scrollup/Scrollup";
import FormSection from "../components/formSection.js/formSection";
import data from "../data/db.json";
import Banner1 from "../components/Banners/Banners1";
import ServicesSection from "../components/ServicesSection/ServicesSection";
import FooterOne from "../components/Footer/FooterOne";
import ReviewOne from "../components/Reviews/ReviewOne";
import BreadcrumbThree from "../components/Breadcrumb/BreadcrumbThree";
import Banner2 from "../components/Banners/Banner2";
import MapSection from "../components/MapSection/MapSection";
import WhatIsBussiness from "../components/Service-Common-Component/whatIsBussiness";
import HorBussinessWork from "../components/Service-Common-Component/HowBussinessWorks";
import BussinessCards from "../components/Service-Common-Component/BussinessCards";
import WhyShouldHire from "../components/Service-Common-Component/WhyShouldHire";
import InvestAffordable from "../components/Service-Common-Component/InvestAffordable";
import WeUsedAdvancedSEO from "../components/Service-Common-Component/WeUsedAdvanced";
import ReasonToChooseUs from "../components/Service-Common-Component/ReasonToChooseUs";
import StrategicProcess from "../components/Service-Common-Component/StrategicProcess";
import HappyClient from "../components/Service-Common-Component/HappyClient";
import Table from "../components/Service-Common-Component/Table";
import Industries from "../components/Service-Common-Component/Indusries";
import Card from "../components/Service-Common-Component/card";
import ServiceFAQs from "../components/Service-Common-Component/ToggleFAQs";
import { useNavigate } from 'react-router-dom';

const ServicesNav2 = ({ pageName }) => {
  const { formSectionData, banner1, banner2, banner3, whatIsbussiness, whatIsbussinessDesc1, whatIsbussinessDesc2 } = data[pageName];
  const { path, h3, h6, desc, list, desc2, button, prevPage, homePage, currantPage, meta_title, meta_desc} =
    formSectionData;

  const navigation = useNavigate()

  const handlePathClick = () => {
    window.history.back();
  };
  const handleHomeClick = () => {
    navigation("/")
  };

  return (
    <div>
       <Helmet>
        <title>{meta_title}</title>  
        <meta name="description" content={meta_desc} />
      </Helmet>
      <Scrollup />
      <div className="main overflow-hidden">
        <HeaderOne imageData={"/img/aaban-text-logo.png"} backgroundColor="#8835dc" />
        <FormSection
          formHeading="GET MY FREE PROPOSAL"
          children={
            <>
              <div className="col-12 col-lg-5">
                <p style={{ color: "#f16334" }} className="pb-4">
                  <span style={{ cursor: "pointer" }} onClick={handleHomeClick}>
                    <strong>{homePage}</strong>
                  </span>{" "}
                  <span style={{ cursor: "pointer" }} onClick={handlePathClick}>
                    / <strong>{prevPage}</strong>
                  </span>{" "}
                  / <strong>{currantPage}</strong>
                </p>
                <h1 className="pb-4" style={{fontSize:'26px'}}>{h3}</h1>
                <h2 className="pb-4" style={{fontSize:'22px'}}>
                  <strong>{h6}</strong>
                </h2>
                <p className="pb-4">{desc}</p>

                <ul>
                  {list?.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>

                <p className=" py-4">{desc2}</p>
                {button && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "40px",
                      backgroundColor: "#f16334",
                      cursor: "pointer",
                    }}
                    onClick={() => alert("Button clicked!")}
                  >
                    <strong>{button}</strong>
                  </div>
                )}
              </div>
            </>
          }
        />

        <Banner2 textColor="text-black" />
        <WhatIsBussiness pageName={pageName} />
        <HorBussinessWork pageName={pageName} />
        <BussinessCards pageName={pageName} />
        <WhyShouldHire pageName={pageName} />
        <WeUsedAdvancedSEO pageName={pageName} />
        <ReasonToChooseUs pageName={pageName} />
        <StrategicProcess pageName={pageName} />
        <HappyClient />
        <Table pageName={pageName} />
        <Industries pageName={pageName} />
        <InvestAffordable pageName={pageName} />
        <Card pageName={pageName} />
        <ServiceFAQs pageName={pageName} />
        <MapSection />
        <FooterOne />
      </div>
    </div>
  );
};

export default ServicesNav2;
