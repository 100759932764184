import React, { useState } from "react";

import data from "../../data/db.json";

const ServicesSection = ({ pageName }) => {
  const { ServicesSection } = data[pageName];
  const [hoverIndex, setHoverIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };
  return (
    <>
      {/* <section className="section bg-grey" style={{ padding: "50px 0" ,}}>
        <div className='container'>
          <div className='justify-content-center text-center align-item-center'>
            <h4 className='m-2' style={{ fontWeight: 400,fontSize:35 }}>{ServicesSection?.heading1}</h4>
            {ServicesSection?.heading2 && <p className='p-2'>{ServicesSection?.heading2}</p>}
            {ServicesSection?.desc && <p >{ServicesSection?.desc}</p>}
          </div>
          <div className="col-lg-12  pr-lg-5">
            <div className="blog blog-right">
              <div className="main overflow-hidden">
                <div className="row bg-grey">
                  {ServicesSection?.list?.map((list, index) => (
                    <div key={index} className="col-lg-6 col-md-12 d-flex ">
                      <div className='card-body '>
                        <div className='d-flex align-items-center ' style={{ gap: '10px' }}>
                          <img src={`/img/${list?.icon}`} alt="Image" style={{ width: '30px' }}  /> 
                          <h5 style={{ fontWeight: 'bold' }} className='pt-3 pb-3'>{list?.heading}</h5>
                        </div>
                        <p>{list?.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >  */}

      {/* ***** changed one *****  */}
      <style>
        {`
          @keyframes slide-out-in {
            0% {
              transform: translateX(0);
            }
            50% {
              transform: translateX(100%);
            }
            51% {
              transform: translateX(-100%);
              opacity:0
            }
            100% {
              transform: translateX(0);
              opacity:1
            }
          }
        `}
      </style>
      <section
        className="section bg-grey px-lg-5 px-sm-0"
        style={{ padding: "50px 0" }}
      >
        <div
          className="container-fluid px-lg-5 px-sm-0"
          style={{
            paddingLeft: "0",
            paddingRight: "0",
          }}
        >
          <div className="justify-content-center text-center align-item-center">
            <h2 className="m-2" style={{ fontWeight: 400, fontSize: 35 }}>
              {ServicesSection?.heading1}
            </h2>
            {ServicesSection?.heading2 && (
              <p
                className="p-2"
                style={{
                  fontWeight: "500",
                  fontSize: "1.1rem",
                  padding: "0 10px",
                }}
              >
                {ServicesSection?.heading2}
              </p>
            )}
            {ServicesSection?.desc && (
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "1.1rem",
                  padding: "0 10px",
                }}
              >
                {ServicesSection?.desc}
              </p>
            )}
          </div>
          <div
            className="col-lg-12 "
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="blog blog-right">
              <div className="main overflow-hidden">
                <div className="row bg-grey">
                  {ServicesSection?.list?.map((list, index) => (
                    <div key={index} className="col-lg-6 col-md-12 d-flex">
                      <div className="card-body">
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: "10px" }}
                        >
                          <img
                            src={`/img/${list?.icon}`}
                            alt="Img"
                            style={{
                              width: "35px",
                              animation:
                                hoverIndex === index
                                  ? "slide-out-in 0.3s forwards"
                                  : "none",
                            }}
                          />
                          <h4
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.5rem",
                              color: hoverIndex === index ? "#fcb900" : "black",
                              cursor: "text",
                            }}
                            className="pt-3 pb-3"
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                          >
                            {list?.heading}
                          </h4>
                        </div>
                        <p
                          style={{
                            fontSize: "1.2rem",
                            paddingTop: "1rem",
                            textAlign: "justify",
                          }}
                        >
                          {list?.desc}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesSection;
