import React from 'react';
import data from '../../data/db.json';

const WhatIsBussiness = ({ pageName }) => {
    const { whatIsbussiness, whatIsbussinessDesc1, whatIsbussinessDesc2 } = data[pageName]

    return (
        <>
            {/* <section className=" my-5">
                <div className="container text-center py-4">
                    <h4 style={{ fontWeight: 800 }}>{whatIsbussiness}</h4>
                    <div className="d-flex justify-content-center align-items-center text-center">
                        <div
                            style={{
                                borderBottom: "3px solid #faa738",
                                width: "5%",
                                height: "20px",
                            }}
                        ></div>
                    </div>
                    <p className="my-3">
                        {whatIsbussinessDesc1}
                    </p>
                    <p className="mt-3">
                        {whatIsbussinessDesc2}
                    </p>
                </div>
            </section>. */}

            {/* ***** changed one *****  */}

            <section className=" container-fluid my-5 px-lg-5 px-sm-0">
                <div className="container-fluid text-center py-4 px-lg-5 px-sm-0">
                    <h2 style={{ fontWeight: 800 , fontSize:"2.1rem"}}>{whatIsbussiness}</h2>
                    <div className="d-flex justify-content-center align-items-center text-center">
                        <div
                            style={{
                                borderBottom: "3px solid #faa738",
                                width: "5%",
                                height: "20px",
                            }}
                        ></div>
                    </div>
                    <p className="my-3" style={{fontWeight:"500" , fontSize:"1.2rem"}}>
                        {whatIsbussinessDesc1}
                    </p>
                    <p className="mt-3" style={{fontWeight:"500", fontSize:"1.2rem"}}>
                        {whatIsbussinessDesc2}
                    </p>
                </div>
            </section>
        </>
    );
};

export default WhatIsBussiness;
