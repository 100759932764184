import React from 'react';
import HeaderOne from '../components/Header/HeaderOne';
import Scrollup from '../components/Scrollup/Scrollup';
import FormSection from '../components/formSection.js/formSection';
import data from "../data/db.json";
import Banner1 from '../components/Banners/Banners1';
import ServicesSection from '../components/ServicesSection/ServicesSection';
import FooterOne from '../components/Footer/FooterOne';
import ReviewOne from '../components/Reviews/ReviewOne';
import WhyChooseUs from '../components/Why-Choose-Us/WhyChooseUs';
import { useNavigate } from 'react-router-dom';
import BreadcrumbForServicepage from '../components/Breadcrumb/BreadcrumbForServicepage';
import DevelopmentServices2 from '../components/Web-Development/DevelopmentServices2';
import CustomerQuestion from '../components/Web-Development/CustomerQuestion';
import { Helmet } from 'react-helmet';


const ServicesNav = ({ pageName }) => {
  const { formSectionData, banner1, banner2, banner3, WhyChooseUs: whyChooseUsData } = data[pageName] || {};
  console.log("ServicesSection", data[pageName].ServicesSection);
  const { path, h3, h6, desc, list, desc2, button, prevPage, homePage, currantPage, meta_title, meta_desc } = formSectionData || {};
  const { banner1Heading, banner1Desc, banner1Image } = banner1 || {};
  const { banner2Heading, banner2Desc, banner2Image } = banner2 || {};
  const { banner3Heading, banner3Desc, banner3Image } = banner3 || {};

  const navigation = useNavigate()
  const handlePathClick = () => {
    if (prevPage === 'Web Development') {
      navigation("/web-development")
    } else if (prevPage === 'Web Advertising') {
      navigation("/digital-advertising")
    } else {
      navigation("/digital-marketing")
    }
  };
  const handleHomeClick = () => {
    navigation("/")
  };
  return (
    <div>
       {/* <Helmet>
        <title>Top SEO Services in USA |Boost Your Online Visibility & RANK</title>  
        <meta name="description" content='Looking for expert SEO services in the USA? Our proven SEO strategies help businesses increase visibility, drive traffic,Get a free SEO audit today! ' />
      </Helmet> */}
      <Helmet>
        <title>{meta_title}</title>  
        <meta name="description" content={meta_desc} />
      </Helmet>
      
      <Scrollup />
      <div className="main overflow-hidden">
        <HeaderOne imageData={"/img/aaban-text-logo.png"}  />
        <BreadcrumbForServicepage/>
        <div >
          <FormSection formHeading='GET MY FREE PROPOSAL'>
            <div className="col-12 col-lg-5">
              <p style={{ color: "#f16334" }} className="pb-4">
                <span style={{ cursor: "pointer" }} onClick={handleHomeClick}>
                  <strong>{homePage}</strong>
                </span>{" "}
                <span style={{ cursor: "pointer" }} onClick={handlePathClick}>
                  /  <strong>{prevPage}</strong>
                </span>{" "}
                / <strong>{currantPage}</strong>
              </p>
              {/* change the heading */}
              <h1 className="pb-4" style={{fontSize:'30px'}}>{h3}</h1>
              <h2 className="pb-4" style={{fontSize:'18px'}}>
                <strong>{h6}</strong>
              </h2>
              <p className="pb-4" style={{ fontWeight: "550" }}>{desc}</p>
              <ul>
                {list?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              <p className=" py-4" style={{ fontWeight: "550" }}>{desc2}</p>
              {button &&
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "40px",
                    backgroundColor: "#f16334",
                    cursor: "pointer",
                  }}
                  onClick={() => alert("Button clicked!")}
                >
                  <strong>{button}</strong>
                </div>
              }
            </div>
          </FormSection>
        </div>

        {banner1Heading && <Banner1 banner1Heading={banner1Heading} banner1Desc={banner1Desc} image={`/img/${banner1Image}`} />}

        {data[pageName].ServicesSection && <ServicesSection pageName={pageName} />}

        {banner2Heading && <Banner1 banner1Heading={banner2Heading} banner1Desc={banner2Desc} image={`/img/${banner2Image}`} flexReverse={"flex-row-reverse"} />}

        {banner3Heading && <Banner1 banner1Heading={banner3Heading} banner1Desc={banner3Desc} image={`/img/${banner3Image}`} />}

        {whyChooseUsData && <WhyChooseUs {...whyChooseUsData} />}

        {pageName === "seo-services-usa" && <DevelopmentServices2 pageName={pageName}/>}

        {pageName === "seo-services-usa" && <CustomerQuestion pageName={ pageName }/>}

        <ReviewOne />
        <FooterOne />
      </div>
    </div>
  )
}

export default ServicesNav;
