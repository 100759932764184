import React, { useState, useEffect } from "react";

import HeaderOne from "../components/Header/HeaderOne";
import FooterOne from "../components/Footer/FooterOne";
import BreadcrumbSection from "../components/Breadcrumb/BreadcrumbTwo";
import ReviewOne from "../components/Reviews/ReviewOne";
import PricingTwo from "../components/Pricing/PricingTwo";
import PricingCardsWebPackages from "../components/Pricing/PricingCardsWebPackages";
import PricingAmazon from "../components/Pricing/PricingAmazon";
import PricingPPC from "../components/Pricing/PricingPPC-packages";
import Maintenance from "../components/SMO-Packages/Maintenance";
import SEOpricing from "../components/Pricing/SEOpricing";
import SMOPricing from "../components/Pricing/SMOpricing"
import PocketFriendlySeoPackages from "../components/Pricing/PocketFriendlySeoPackages";
import CorporateSEOPackages from "../components/Pricing/CorporateSEOPackages";



const PricingSection = ({ pageName }) => {
  console.log("pageName", pageName);

  let pricingComponent;

  if (pageName === "seo-pricing") {
    pricingComponent = <SEOpricing pageName={pageName} />;
  } else if (pageName === "web-packages") {
    pricingComponent = <PricingCardsWebPackages pageName={pageName}/>;
  } else if (pageName === "amazon-ads-services") {
    pricingComponent = <PricingAmazon pageName={pageName}/>;
  } else if (pageName == "ppc-packages") {
    pricingComponent = <PricingPPC pageName={pageName}/>;
  } else if (pageName == "smo-pricing") {
    pricingComponent = <SMOPricing pageName={pageName}/>;
  }  else if (pageName == "pocket-friendly-seo-packages") {
    pricingComponent = <PocketFriendlySeoPackages pageName={"PocketFriendlySEOPackages"}/>
  } else if (pageName == "corporate-seo-packages") {
    pricingComponent = <CorporateSEOPackages pageName={"CorporateSEOPackages"}/>
  } 
  

  return (
    <>
       <HeaderOne
        imageData={"/img/aaban-text-logo.png"}
      />
      <BreadcrumbSection pageName={pageName}/>
      {pricingComponent}
      <PricingTwo pageName={pageName} />    
      <ReviewOne/>
      <FooterOne />
    </>
  );
};

export default PricingSection;
