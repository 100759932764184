import data from "../data/db.json";
import React from "react";

import FooterOne from "../components/Footer/FooterOne";
import HeaderOne from "../components/Header/HeaderOne";
import FormSection from "../components/formSection.js/formSection";
import BlogRightSidebar from "../components/Blogs/BlogRightSidebar";
import { useNavigate } from "react-router-dom";
import MarketFAQ from "../components/Market-FAQ/MarketFAQ";
import BreadcrumbForServicepage from "../components/Breadcrumb/BreadcrumbForServicepage";
import { Helmet } from "react-helmet";

const Market = ({ pageName }) => {
  const questionData = data[pageName]["customer-questions"];

  const { formSectionData, banner1 } = data[pageName];
  const {
    path,
    h3,
    h6,
    desc,
    list,
    desc2,
    desc3,
    prevPage,
    currantPage,
    button,
    meta_title,
    meta_desc
  } = formSectionData;
  const navigation = useNavigate();
  const handlePathClick = () => {
    navigation("/");
  };
  const handleSEOmarketClick = () => {
    navigation("/seo-market");
  };

  return (
    <>
      <HeaderOne imageData={"/img/aaban-text-logo.png"} />
      <BreadcrumbForServicepage />
      <Helmet>
        <title>{meta_title}</title>  
        <meta name="description" content={meta_desc} />
      </Helmet>
      <div>
        <FormSection
          formHeading="GET MY FREE PROPOSAL"
          pageName={pageName}
          children={
            <>
              <div className="col-12 col-lg-5">
                <p style={{ color: "#f16334" }} className="pb-4">
                  <span style={{ cursor: "pointer" }} onClick={handlePathClick}>
                    <strong>Home / </strong>
                  </span>{" "}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleSEOmarketClick}
                  >
                    <strong>{prevPage}</strong>
                  </span>{" "}
                  / <strong>{currantPage}</strong>
                </p>
                <h1 className="pb-4" style={{fontSize:'28px'}}>{h3}</h1>
                <h4 className="pb-4">
                  <strong>{h6}</strong>
                </h4>
                <p className="pb-4" style={{ fontWeight: "550" }}>
                  {desc}
                </p>

                <ul>
                  {list?.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>

                <p className=" py-4" style={{ fontWeight: "550" }}>
                  {desc2}
                </p>
                {button && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "40px",
                      backgroundColor: "#f16334",
                      cursor: "pointer",
                    }}
                    onClick={() => alert("Button clicked!")}
                  >
                    <strong>{button}</strong>
                  </div>
                )}
              </div>
            </>
          }
        />
      </div>

      <BlogRightSidebar pageName={pageName} />

      {questionData ? <MarketFAQ pageName={pageName} /> : ""}

      <FooterOne />
    </>
  );
};

export default Market;
