import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaPinterest } from "react-icons/fa";
import { BiLogoDribbble } from "react-icons/bi";
import { FaSquareBehance } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaSkype } from "react-icons/fa";
import {
  // EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/digimax-json-2/themeOneFooterSection";

const FooterOne = () => {
  const [data, setData] = useState({});
  const [footerList_1, setFooterList1] = useState([]);
  const [footerList_2, setFooterList2] = useState([]);
  const [footerList_3, setFooterList3] = useState([]);
  const [iconList, setIconList] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        setData(res.data);
        setFooterList1(res.data.footerList_1);
        setFooterList2(res.data.footerList_2);
        setFooterList3(res.data.footerList_3);
        setIconList(res.data.iconList);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleWhatsAppClick = () => {
    const phoneNumber = "7046787233";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  return (
    <footer className="section footer-area">
      <div className="footer-top ptb_100">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="footer-items">
                <h4 className="footer-title mb-2">About Us</h4>
                <div>
                  <p style={{ lineHeight: 2 }}>
                    We specialize in providing solutions that help businesses
                    thrive and succeed. Whether you're a small startup or a
                    large corporation, we have the expertise and tools to
                    support your growth journey.
                  </p>
                </div>
                <hr />
                <strong> social community</strong>
                {/* <ul className="social-icons list-inline pt-2 d-flex flex-wrap" style={{ gap: "15px", width: '80%' }}>
                                    <FacebookShareButton className='' url="https://www.facebook.com/AabanSolutions/">
                                        <FacebookIcon className='hover' size={23} round={true} />
                                    </FacebookShareButton>
                                    <TwitterShareButton className='' url='#'>
                                        <XIcon className='hover' size={23} round={true} />
                                    </TwitterShareButton>
                                    <LinkedinShareButton url='https://www.linkedin.com/company/aaban-solutions/'>
                                        <LinkedinIcon className='hover' size={23} round={true} />
                                    </LinkedinShareButton>
                                    <li><a href='https://in.pinterest.com/aabansolutions313/' target='_blank'><FaPinterest className='pinterest ' style={{ height: '23px' }} /></a></li>
                                    <li><a href='#' target='_blank'><BiLogoDribbble className='dribbble' style={{ height: '23px' }} /></a></li>
                                    <li><a href='https://www.behance.net/aaban/' target='_blank'><FaSquareBehance className='behance' style={{ height: '23px' }} /></a></li> */}
                {/* <WhatsappShareButton >
                                        <WhatsappIcon className='hover' size={23} round={true} />
                                    </WhatsappShareButton> */}
                {/* <div onClick={handleWhatsAppClick} style={{cursor: "pointer"}}>
                                       <WhatsappIcon className='hover' size={23} round={true} />
                                    </div>
                                    <li><a href='#' target='_blank'><FaYoutube className='youTube' style={{ height: '30px' }} /></a></li>
                                    <li><a href='#' target='_blank'><FaSkype className='skype' style={{ height: '23px' }} /></a></li>
                                </ul> */}

                {/* ***** changed one ***** */}

                <ul
                  className=" list-inline pt-2 d-flex flex-wrap"
                  style={{ gap: "15px", width: "80%" }}
                >
                  <a
                    href="https://www.facebook.com/AabanSolutions/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon className="hover" size={23} round={true} />
                  </a>
                  <a
                    href="https://x.com/AabanSolutions_"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <XIcon className="hover" size={23} round={true} />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/aaban-solutions/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedinIcon className="hover" size={23} round={true} />
                  </a>

                  <li>
                    <a
                      href="https://in.pinterest.com/aabansolutions313/"
                      target="_blank"
                    >
                      <FaPinterest
                        size={23}
                        className="pinterest "
                        style={{ height: "23px" }}
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <BiLogoDribbble
                        size={23}
                        className="dribbble"
                        style={{ height: "23px" }}
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.behance.net/aaban/" target="_blank">
                      <FaSquareBehance
                        size={23}
                        className="behance"
                        style={{ height: "23px" }}
                      />
                    </a>
                  </li>
                  {/* <WhatsappShareButton >
                                        <WhatsappIcon className='hover' size={23} round={true} />
                                    </WhatsappShareButton> */}
                  <div
                    onClick={handleWhatsAppClick}
                    style={{ cursor: "pointer" }}
                  >
                    <WhatsappIcon className="hover" size={23} round={true} />
                  </div>
                  <li>
                    <a href="#" target="_blank">
                      <FaYoutube
                        size={23}
                        className="youTube"
                        style={{ height: "30px" }}
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <FaSkype
                        size={23}
                        className="skype"
                        style={{ height: "23px" }}
                      /> 
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3"> 
              {/* Footer Items */}
              <div className="footer-items">
                {/* Footer Title */}
                <h3 className="footer-title mb-2">Services</h3>
                <ul>
                  <li className="py-2">
                    <a
                      className="text-black-50"
                      href="/digital-marketing/seo-services-usa"
                    >
                      Search Engine Optimization (SEO)
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      className="text-black-50"
                      href="/digital-marketing/affordable-seo-services-for-small-business"
                    >
                      Small Business SEO
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      className="text-black-50"
                      href="/digital-marketing/local-seo-services"
                    >
                      Local SEO Services
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      className="text-black-50"
                      href="/digital-marketing/ecommerce-seo-services"
                    >
                      E-commerce SEO
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="#">
                      Website Development
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      className="text-black-50"
                      href="/digital-marketing/website-redesign-service"
                    >
                      Website Re-Designing
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      className="text-black-50"
                      href="/digital-marketing/mobile-app-development"
                    >
                      Application Development
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              {/* Footer Items */}
              <div className="footer-items">
                {/* Footer Title */}
                <h3 className="footer-title mb-2">Important Links</h3>
                <ul>
                  {/* <li className="py-2">
                    <a className="text-black-50" href="/testimonial">
                      Testimonial
                    </a>
                  </li> */}
                  <li className="py-2">
                    <a className="text-black-50" href="/aaban-portfolio">
                      Portfolio
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/blog">
                      Blog
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/pricing/seo-pricing">
                      Pricing
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/about-us">
                      About Us
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/contact">
                      Contact Us
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/terms-of-service">
                      Terms of Service
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/privacy-policy">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3">
              {/* Footer Items */}
              <div className="footer-items">
                {/* Footer Title */}
                <h2 className="footer-title mb-2" style={{fontSize:'25px'}}>Get In Touch</h2>
                <p className="mb-2">{``}</p>
                {/* Social Icons */}

                <div className="mt-4">
                  <p className="text-black-50" style={{ marginTop: 10 }}>
                    <strong>Aaban Solutions LLC</strong>
                    <br /> 1519 W Warren Blvd, Chicago, IL 60607, USA
                  </p>
                  <p className="my-3 text-black-50">
                    <strong>Phone: </strong>
                    <a>+(1) (312) 5985838</a>
                  </p>
                  <p className="my-3 text-black-50">
                    <strong>Phone: </strong>
                    <a>+(1) (872) 278-4146</a>
                  </p>
                  <p className="text-black-50">
                    <strong>Email:</strong> <a> info@aabansolutions.com</a>
                  </p>
                  <hr />
                  {/* <p className="text-black-50" ><strong>INDIA:    Development Hub</strong><br /> Plot No. 249, M,Udyog Vihar Phase IV, Gurgaon, Haryana 122016, India</p> */}
                  {/* <p className='my-3 text-black-50'><strong>Phone:  </strong><a>+91-90453 15147</a></p> */}
                  <a
                    href="/free-consultation"
                    className="btn btn-primary"
                    style={{ marginTop: "10%" }}
                  >
                    Schedule a free call
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Bottom */}
      <div className="footer-bottom bg-grey">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Copyright Area */}
              <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                {/* Copyright Left */}
                <div className="copyright-left">
                  2015 - 2024 Aaban Solution LLC. All Rights Reserved
                </div>
                {/* Copyright Right */}
                <div className="copyright-right">
                  Made with <i className="fas fa-heart" /> By{" "}
                  <a href="/#">Aaban Solutions</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
