import React from "react";
import Banner1 from "../FoodDeleveryBanners/Banners1";
import Banner2 from "../FoodDeleveryBanners/Banners2";
import Banner3 from "../FoodDeleveryBanners/Banners3";
import Banner4 from "../FoodDeleveryBanners/Banners4";
import Banner5 from "../FoodDeleveryBanners/Banners5";
import Banner6 from "../FoodDeleveryBanners/Banners6";
import Banner7 from "../FoodDeleveryBanners/Banners7";
import Banner8 from "../FoodDeleveryBanners/Banners8";
import Card from "../Cards/card";
import ReviewOne from "../Reviews/ReviewOne";
import { Helmet } from "react-helmet";

const FoodDelivery = ({ pageName }) => {
  return (
    <>
      {/* <section className="bg-grey">
        <div className="container flex-column d-flex justify-content-center align-items-center pt-5">
          <h2>ONLINE FOOD DELIVERY APPLICATION</h2>
          <div className="container justify-content-around align-items-center d-flex ">
            <div className="row">
              <div className="col-md-7 col-sm-12">
                <div className="d-flex flex-column m-5 align-items-center">
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Necessitatibus aliquam nam odio animi suscipit eum? Iusto
                    beatae pariatur nam perspiciatis! Lorem ipsum dolor sit amet
                    consectetur adipisicing elit. Reprehenderit recusandae atque
                    necessitatibus obcaecati, repellendus impedit enim cum vero
                    incidunt veniam.
                  </p>
                  <p className="my-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Reprehenderit recusandae atque necessitatibus obcaecati,
                    repellendus impedit enim cum vero incidunt veniam.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Beatae, odit? Lorem ipsum dolor sit amet consectetur
                    adipisicing elit. Reprehenderit recusandae atque
                    necessitatibus obcaecati, repellendus impedit enim cum vero
                    incidunt veniam.
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-12">
                <div className="d-flex m-3 align-items-center ">
                  <img src="/img/foodDeviver.webp" alt="fooddelivery" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* **** Changed One *****  */}

      <Helmet>
        <title>Food Delivery App |Build Your Custom Online Ordering System!</title> {/* Dynamic meta title from the JSON data */}
        <meta name="description" content='Boost your restaurant’s reach with a custom food delivery app. Enjoy app development, order management, tracking, and payment integration.  your solution today!' /> {/* Dynamic meta description from the JSON data */}
      </Helmet>  
      <section className="bg-grey pb-5">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center">
          <h1 style={{fontSize:'35px',paddingTop:'30px'}}>ONLINE FOOD DELIVERY APPLICATION</h1>
          <div className="container-fluid justify-content-around align-items-center d-flex mt-3 px-lg-5 px-sm-0">
            <div className="row">
              <div
                className="col-md-7 col-sm-12"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="d-flex flex-column m-2 align-items-center px-lg-5 px-sm-0">
                  <p style={{ fontSize: "1.3rem", fontWeight: "500" }}>
                  Maximize your restaurant’s reach with a branded food delivery app. 
                  Our services include app development, order management, real-time tracking,
                  and payment integration to streamline your delivery process. Get your custom app solution now.
                  </p>
                  <p
                    className="my-4"
                    style={{ fontSize: "1.3rem", fontWeight: "500" }}
                  >
                    It is known globally that, in today’s market, it is
                    extremely difficult to start a new small-scale business and
                    live through the competition from well-established and
                    settled owners. In the fast-paced times of today, when
                    everyone is squeezed for time, the majority of people are
                    worried when it comes to placing a food order. The customers
                    of today are not only attracted because placing an order
                    online is very convenient but also because they have
                    visibility into the items offered, price, and extremely
                    simplified navigation for the order.
                  </p>
                  <p style={{ fontSize: "1.3rem", fontWeight: "500" }}>
                    The online ordering system here greatly simplifies the
                    ordering process for both the customer and the restaurant.
                    The system presents an interactive and up-to-date menu with
                    all available options in an easy-to-use manner. Customers
                    can choose items to place an order which will land in the
                    Cart.
                  </p>
                </div>
              </div>
              <div
                className="col-md-5 col-sm-12"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="d-flex align-items-center">
                  <img
                    src="/img/foodDeviver.webp"
                    alt="fooddelivery"
                    style={{
                      height: "auto",
                      width: "100%",
                      maxWidth: "600px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <Card />
        <Banner1 />
        <Banner2 />
        <Banner3 />
        <Banner4 />
        <Banner5 />
        <div className="pb-5 bg-grey">
          <Banner6 />
        </div>
        <Banner7 pageName={pageName} />
        <Banner8 pageName={pageName} />
        <ReviewOne />
      </section>
    </>
  );
};

export default FoodDelivery;
