import React from "react";
import { Link } from "react-router-dom";


const Banner3 = () => {
  return (
    <>
      <section
        className="section bg-gray pb-5"
        style={{
          padding: "50px ",
          backgroundImage: `url('/img/pexels-putra-arin-wiratama-410487.jpg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex-column d-flex justify-content-center align-items-center text-center">
          <h2 className="text-white">Turn Your Prospects Into Buyers</h2>
          <h4 className="py-3 text-white">
            Contact us today to get connected with one of our experts.
          </h4>
          <Link to="/contact">
            <button type="button" class="btn bg-yellow" >
              CONTACT US NOW
            </button>
          </Link>
        </div>
      </section>
      
    </>
  );
};

export default Banner3;
