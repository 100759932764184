import React, { useRef, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import HeaderOne from "../components/Header/HeaderOne";
import Imageframe from "../components/ImageFrame/ImageFrame";
import HeroOne from "../components/Hero/HeroOne";
import PromoOne from "../components/Promo/PromoOne";
import ContentOne from "../components/Content/ContentOne";
import ReviewOne from "../components/Reviews/ReviewOne";
import FooterOne from "../components/Footer/FooterOne";
import Banner2 from "../components/Banners/Banner2";
import NewsCard from "../components/Card/NewsCard";
import NotchServices from "../components/NotchServices/NotchServices";
import PricingForHomePage from "../components/Pricing/PricingForHomePage";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { MdCancel } from "react-icons/md";
import ChatBot from "react-simple-chatbot";
import { BiCommentDetail } from "react-icons/bi";

const ThemeOne = () => {
  const [showModal, setShowModal] = useState(true);
  const [showChatbot, setShowChatbot] = useState(false);
  const [step, setStep] = useState([]);
  const formRef = useRef(null);
  const modalRef = useRef(null);
  const [formData, setFormData] = useState({
    userName: "",
    userEmail: "",
    websiteUrl: "",
    user_mobile:"",
  });

  const handleClose = () => setShowModal(false);

  useEffect(() => {
    const chatHistory = JSON.parse(localStorage.getItem("chatHistory")) || [];
    setStep(chatHistory);
  }, []);

  useEffect(() => {
    setShowModal(true);
  }, []);

  const handleUserInput = (newStep) => {
    const updatedSteps = [...step, newStep];
    const lastArray = updatedSteps.slice(-1)[0]?.values;
    setStep(updatedSteps);
    localStorage.setItem("chatHistory", JSON.stringify(updatedSteps));

    const [user_name, user_email, message] = lastArray;

    emailjs
      .send(
        "service_56xelui",
        "template_38c1qv8",
        {
          from_name: user_name,
          to_email: "info@aabansolution.com",
          user_email: user_email,
          message: message,
        },
        "cpTPA4zZLmKmwE9nt"
      )
      .then(
        (result) => {
          console.log(result);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your query has been successfully sent. Thank you!",
            showConfirmButton: true,
          });
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Error",
            text:
              "There was an error sending your query. Please try again later.",
          });
        }
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (!validateForm()) {
    //     return;
    // }

    emailjs
      .sendForm(
        "service_56xelui",
        "template_38c1qv8",
        formRef.current,
        "cpTPA4zZLmKmwE9nt"
      )
      .then(
        (result) => {
          console.log(result);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your Query Send Successfully, Thank You For Contacting us!",
            showConfirmButton: true,
          });
          formRef.current.reset();
          setFormData({
            userName: "",
            userEmail: "",
            websiteUrl: "",
             user_mobile:"",
          });
          // setShowModal(false);
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.text,
          });
        }
      );
  };

  // const handleSubmitModal = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm(
  //       "service_56xelui",
  //       "template_38c1qv8",
  //       formRef.current,
  //       "cpTPA4zZLmKmwE9nt"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result);
  //         Swal.fire({
  //           icon: "success",
  //           title: "Success",
  //           text: "Your Query Send Successfully, Thank You For Contacting us!",
  //           showConfirmButton: true,
  //         });
  //         formRef.current.reset();
  //         setFormData({
  //           userName: "",
  //           userEmail: "",
  //           websiteUrl: "",
  //         });
  //         setShowModal(false);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //         Swal.fire({
  //           icon: "error",
  //           title: "Error",
  //           text: error.text,
  //         });
  //       }
  //     );
  // };

  // Updated handleSubmitModal function
  
  
  const handleSubmitModal = (e) => {
    e.preventDefault();
  
    const form = modalRef.current;
    const userName = form.user_name.value.trim();
    const userEmail = form.user_email.value.trim();
    const userMobile = form.user_mobile.value.trim();
  
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[0-9]{10}$/;
  
    if (!userName) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please enter your name.",
      });
      return;
    }
  
    if (!emailPattern.test(userEmail)) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please enter a valid email address.",
      });
      return;
    }
  
    if (!phonePattern.test(userMobile)) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please enter a valid 10-digit mobile number.",
      });
      return;
    }
    emailjs
      .sendForm(
        "service_56xelui",
        "template_38c1qv8",
        modalRef.current,
        "cpTPA4zZLmKmwE9nt"
      )
      .then(
        (result) => {
          console.log(result);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your query has been successfully sent. Thank you!",
            showConfirmButton: true,
          });
          form.reset();
          setFormData({
            userName: "",
            userEmail: "",
            websiteUrl: "",
            user_mobile: "",
          });
          setShowModal(false);
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "There was an error sending your query. Please try again later.",
          });
        }
      );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.userEmail.match(emailPattern)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a valid email address.",
      });
      return false;
    }

    const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
    if (!formData.websiteUrl.match(urlPattern)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a valid website URL.",
      });
      return false;
    }

    if (formData.userName.trim() === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter your name.",
      });
      return false;
    }

    return true;
  };
  

  const steps = [
    {
      id: "1",
      message: "What is your email?  ",
      trigger: "businessName",
    },
    {
      id: "businessName",
      user: true,
      trigger: "2",
      validator: (value) => {
        if (!value || value.trim() === "") {
          return "Please enter the name of your business.";
        }
        return true;
      },
    },
    {
      id: "2",
      message: "Please select the services you are interested in:",
      trigger: "services",
    },
    {
      id: "services",
      options: [
        { value: "Web Development", label: "Web Development", trigger: "3" },
        { value: "SEO", label: "SEO", trigger: "3" },
        {
          value: "Social Media Marketing",
          label: "Social Media Marketing",
          trigger: "3",
        },
      ],
    },
    {
      id: "3",
      message: "Please provide any additional details or requirements:",
      trigger: "additionalDetails",
    },
    {
      id: "additionalDetails",
      user: true,
      trigger: "4",
    },
    {
      id: "4",
      message:
        "Thank you! Please share your requirements to here info@aabansolution.com.",
      end: true,
    },
  ];

  return (
    <div>
      <div className="main overflow-hidden">
        <HeaderOne imageData={"/img/aaban-text-logo.png"} />
        <HeroOne />
        <PromoOne />
        <Imageframe />
        <NotchServices />
        <Banner2 textColor="text-black" />
        <PricingForHomePage />
       <Modal show={showModal} onHide={handleClose} centered size="lg">
          <Modal.Header style={{ backgroundColor: "#973de4", color: "#fff" }}>
            <Modal.Title style={{ margin: "0 auto" }}>
              Let's Start and Grow your Business
            </Modal.Title>
            <MdCancel size={28} onClick={handleClose} cursor={"pointer"} />
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: "#f8f9fa",
              minHeight: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="d-flex col-12 model-form-style">
              <div className="col-md-6">
                <p style={{ color: "#973de4" }}>
                  <i className="fa-solid fa-user-plus"></i> 100+ Happy Clients
                </p>
                <hr style={{ borderTop: "1px solid #973de4" }} />
                <p style={{ color: "#973de4" }}>
                  <i className="fa-solid fa-phone-volume"></i> 9,78,00+ Calls
                  Generated
                </p>
                <hr style={{ borderTop: "1px solid #973de4" }} />
                <p style={{ color: "#973de4" }}>
                  <i className="fa-solid fa-user-plus"></i> $1,80,000+ Revenue
                  Generated
                </p>
                <hr style={{ borderTop: "1px solid #973de4" }} />
                <p style={{ color: "black" }}>
                  By submitting my contact details here, I consent to receive
                  emails & text messages from Aaban Solution.
                </p>
              </div>
              <form
                className="col-md-6"
                style={{ marginLeft: "5%" }}
                ref={modalRef}
                onSubmit={handleSubmitModal}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="User Name"
                  name="user_name"
                  style={{ marginBottom: "20px", height: "50px" }}
                />
                <input
                  type="email"
                  className="form-control"
                  placeholder="User Email"
                  name="user_email"
                  style={{ marginBottom: "20px", height: "50px" }}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mobile Number"
                  name="user_mobile"
                  maxLength='10'
                  style={{ marginBottom: "20px", height: "50px" }}
                />
                <input
                  className="form-control"
                  placeholder="Message"
                  name="message"
                  style={{ marginBottom: "20px", height: "100px" }}
                />
                <Button
                  variant="primary"
                  type="submit"
                  style={{ backgroundColor: "#973de4", borderColor: "#973de4" }}
                >
                  Submit
                </Button>
              </form>
            </div>
          </Modal.Body>
        </Modal>
        <ContentOne />

        <div
          className=""
          style={{
            width: "100%",
            backgroundImage: "url('/img/dotted.png')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            border: "1px solid transparent",
          }}
        >
          <section className="container text-center px-0">
            <h3 className="mt-5 mb-3 pt-3">Our News </h3>
            <div
              className="d-flex news-card "
              style={{
                gap: "30px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <NewsCard
                image="lsi-keywords"
                question="LSI Keywords: How do related words improve SEO?"
                date="February 22nd, 2024"
                disc="Remember when websites were stuffed with keywords, trying to reach the top of search results? Gone are those SEO tactics"
              />
              <NewsCard
                image="SEO-news"
                question="Off Page SEO Essentials: A beginner’s guide to promote your website"
                date="February 22nd, 2024"
                disc="Suppose you have bought a vintage house with a huge living room, spacious bedrooms, a vast garden, etc. However, no"
              />
              <NewsCard
                image="important-fector-sco"
                question="Essential On Page SEO Factors You Must Know"
                date="February 22nd, 2024"
                disc="Today, most people expect to find whatever they search at the top of Google search results. If your website is"
              />
            </div>
          </section>
        </div>

        <ReviewOne />
        <FooterOne />
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "50%",
          padding: "10px",
          backgroundColor: "#ffffff",
          zIndex: "10",
        }}
      >
        <BiCommentDetail
          size={40}
          onClick={() => setShowChatbot(!showChatbot)}
          style={{ cursor: "pointer", color: "#973de4" }}
        />
      </div>
      {showChatbot && (
        <div
          style={{
            position: "fixed",
            bottom: "80px",
            right: "20px",
            zIndex: "9998",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              maxWidth: "370px",
            }}
          >
            <ChatBot
              steps={steps}
              handleEnd={(newStep) => handleUserInput(newStep)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ThemeOne;
