import React from 'react';
import data from '../../data/db.json';

const WhyShouldHire = ({ pageName }) => {
    const { WhyShouldHire, list } = data[pageName]; 
    
    return (
        <>
            <section className="bg-grey my-5">
                <div className="container  py-4 blackColor" >
                    {WhyShouldHire?.map((item, key) => {
                        return (
                            <>
                                <h3 style={{fontWeight:800}}>{item?.heading}</h3>
                                <p className="my-3">
                                    {item?.desc}
                                </p>
                            </>
                        )
                    })}
                </div>
            </section>
        </>
    );
};

export default WhyShouldHire;
