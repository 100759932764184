import React from "react";

const Banner1 = ({ banner1Heading, banner1Desc, image, flexReverse }) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  return (
    <>
      {/* <section
        className={`section`}
        style={{ padding: "50px 0", backgroundColor: "#f8f9fa" }}
      >
        <div className="container">
          <div className={`row ${flexItemReverse}`} >
            <div className="col-lg-8">
              <h3>{banner1Heading}</h3>
              <p>
                {banner1Desc}
              </p>
            </div>
            <div className="col-lg-4 d-flex align-items-center justify-content-center banner1-image-style">
              <img height="180" width="185" src={image} alt="SEO" />
            </div>
          </div>
        </div>
      </section> */}

      {/* ***** Changed one *****  */}

      <section
        className={`section px-lg-5 px-sm-0`}
        style={{
          padding: "10px",
          backgroundColor: "#f8f9fa",
        }}
      >
        <style>
          {`
      @media (max-width: 576px) {
        .responsive-heading {
          font-size: 1.2rem;
        }
        .responsive-paragraph {
          font-size: 1rem;
        }
      }
      @media (min-width: 577px) and (max-width: 768px) {
        .responsive-heading {
          font-size: 1.5rem;
        }
        .responsive-paragraph {
          font-size: 1.1rem;
        }
      }
      @media (min-width: 769px) {
        .responsive-heading {
          font-size: 2rem;
        }
        .responsive-paragraph {
          font-size: 1.3rem;
        }
      }
    `}
        </style>
        <div
          className="container-fluid"
          style={{ padding: 0 }}
        >
          <div className={`row ${flexItemReverse} px-lg-5 px-sm-0`}>
            <div
              className="col-lg-8"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "justify",
                padding: 0,
              }}
            >
              <h2
                className="pb-sm-0 m-0 px-3 responsive-heading"
                style={{
                  margin: 0,
                }}
              >
                {banner1Heading}
              </h2>
              <p
                className="px-3 responsive-paragraph py-3"
                style={{
                  margin: 0,
                  fontWeight: "450",
                }}
              >
                {banner1Desc}
              </p>
            </div>
            <div
              className="col-lg-4 d-flex align-items-center justify-content-center banner1-image-style"
            >
              <img
                src={image}
                alt="SEO"
                style={{
                  height: "300px",
                  width: "300px",
                  objectFit: "cover",
                  maxWidth: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner1;
