import React, { useState, useEffect } from "react";
import axios from "axios";
import data from "../../data/db.json";
import { Helmet } from "react-helmet";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/digimax-json-1/themeOnePricingSection";

const initData = {
  text_1: "Haven't find suitable answer?",
  text_2: "Tell us what you need.",
};

const PricingCardsWebPackages = () => {
  const [initData, setInitData] = useState({});
  const [pricingData, setPricingData] = useState([]);
  const [faqData, setFaqData] = useState([]);
  const [faqData_1, setFaqData_1] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        setInitData(initData);
        setPricingData(res.data.pricingData);
        setFaqData(faqData);
        setFaqData_1(faqData_1);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
     <section
      id="pricing"
      className="section price-plan-area overflow-hidden ptb_50 home-pricing-boxes"
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "12px",
        paddingRight: "12px",
        backgroundImage: "url('/img/background1.jpg')",
        backgroundSize: "cover",
        color: "white",
      }}
    >
       <Helmet>
        <title>Web Design & Development | Professionals Solutions Business!</title> {/* Dynamic meta title from the JSON data */}
        <meta name="description" content='Get a customized web package with SEO-optimized designs and advanced functionality. All-in-one to help your business grow. Start with a free consultation today!' />
      </Helmet>
      <div
        className="overlay"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 2,
        }}
      ></div>
      <div className="container" style={{ position: "relative", zIndex: 3 }}>
        <h3 className="text-center text-white">
          {data.WebpackagePricingSection.heading}
        </h3>
        <h3 className="text-center text-white" style={{ fontWeight: 300 }}>
          {data.WebpackagePricingSection.headingText_1}
        </h3>
        <div className="d-flex justify-content-center">
          <div className="row price-plan-wrapper justify-content-center">
            {data.WebpackagePricingSection.pricingData.map((item, idx) => (
              <div
                key={`pro_${idx}`}
                className={`col-12 col-sm-6 col-md-4 col-lg-3 p-1 d-flex`}
              >
                <div
                  className="single-price-plan color-1 bg-hover hover-top pt-4"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    border: `8px solid ${item.borderColor}`,
                    width: "100%",
                  }}
                >
                  <div className="plan-title mb-2 mb-sm-3">
                    <div className="d-flex align-items-center justify-content-center">
                      <h3 style={{ color: item.titleColor }} className="mb-2">
                        {item.title}
                      </h3>
                      {item.price_badge && (
                        <img
                          className=""
                          width={100}
                          src="/img/best-saller.jpg"
                          alt="image not found"
                        />
                      )}
                    </div>
                  </div>
                  <div className="plan-price pb-2 pb-sm-3 text-center">
                    <span
                      className="color-primary fw-7 text-center"
                      style={{ color: item.priceDigitStyle }}
                    >
                      {item.price_before}
                    </span>
                    <span
                      className="h1 fw-7 text-center"
                      style={{ color: item.priceDigitStyle }}
                    >
                      {item.price}
                    </span>
                    <sub className="validity text-muted fw-5">
                      {item.price_after}
                    </sub>
                  </div>
                  <div className="plan-description text-center">
                    <ul className="plan-features">
                      <li className={item.listClass_2}>
                        {item.li_1 ? item.li_1 : "-"}
                      </li>
                      <hr className="pricing-hr-style" />
                      <li className={item.listClass_2}>
                        {item.li_2 ? item.li_2 : "-"}
                      </li>
                      <hr className="pricing-hr-style" />
                      <li className={item.listClass_2}>
                        {item.li_3 ? item.li_3 : "-"}
                      </li>
                      <hr className="pricing-hr-style" />
                      <li className={item.listClass_2}>
                        {item.li_4 ? item.li_4 : "-"}
                      </li>
                      <hr className="pricing-hr-style" />
                      <li className={item.listClass_2}>
                        {item.li_5 ? item.li_5 : "-"}
                      </li>
                      <hr className="pricing-hr-style" />
                      <li className={item.listClass_2}>
                        {item.li_6 ? item.li_6 : "-"}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingCardsWebPackages;
